import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`💡 Wikisurvey`}</h1>
    <p>{``}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{` is a `}<a parentName="p" {...{
        "href": "/Wikisurvey",
        "title": "💡 Wikisurvey"
      }}>{`💡 Wikisurvey`}</a>{`.`}</p>
    <p>{`In short, a `}<a parentName="p" {...{
        "href": "/Wikisurvey",
        "title": "💡 Wikisurvey"
      }}>{`💡 Wikisurvey`}</a>{` is a kind of interactive poll that is:`}</p>
    <h3>{`Greedy`}</h3>
    <p>{`The survey will accept any amount of responses`}</p>
    <ul>
      <li parentName="ul">{`ie., participants are not required to complete it to participate`}</li>
      <li parentName="ul">{`any amount of voting or interaction will be factored into the results`}</li>
    </ul>
    <h3>{`Adaptive`}</h3>
    <ul>
      <li parentName="ul">{`becomes more relevant and specific to participants the more information it gathers from them`}</li>
    </ul>
    <h3>{`Collaborative`}</h3>
    <ul>
      <li parentName="ul">{`can be thought of as a survey created by the people taking it`}
        <ul parentName="li">
          <li parentName="ul">{`allows users to create the dimensions or factors or questions on which they will then score themselves`}</li>
        </ul>
      </li>
    </ul>
    <p>{`As a result, Wikisurveys turn the agenda setting power of feedback gathering techniques back to the people being asked for their opinion, rather than retaining the agenda setting power with those constructing the feedback exercise. This facilitates emergent behavior on the part of those responding and interacting with each others' ideas.`}</p>
    <h4>{`See:`}</h4>
    <p><a parentName="p" {...{
        "href": "https://www.nesta.org.uk/blog/crowdsourcing-democracy-using-wikisurveys"
      }}>{`Crowdsourcing for democracy using Wikisurveys`}</a>{` by `}<a parentName="p" {...{
        "href": "/Theo-Bass",
        "title": "Theo Bass"
      }}>{`Theo Bass`}</a>{``}</p>
    <p><a parentName="p" {...{
        "href": "https://arxiv.org/abs/1202.0500"
      }}>{`Wiki surveys: Open and quantifiable social data collection`}</a>{` by `}<a parentName="p" {...{
        "href": "https://arxiv.org/search/stat?searchtype=author&query=Salganik%2C+M+J"
      }}>{`Matthew J. Salganik`}</a>{`, `}<a parentName="p" {...{
        "href": "https://arxiv.org/search/stat?searchtype=author&query=Levy%2C+K+E+C"
      }}>{`Karen E. C. Levy`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      